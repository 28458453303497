:root {
  --default-font: "Lato", system-ui, -apple-system, "Segoe UI", Lato, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Raleway", sans-serif;
  --nav-font: "Poppins", sans-serif;
  --background-color: #fff;
  --default-color: #444;
  --heading-color: #2a2c39;
  --accent-color: #60a5fa;
  --surface-color: #2563eb;
  --contrast-color: #fff;
  --nav-color: #e0e7ff;
  --nav-hover-color: #60a5fa;
  --nav-mobile-background-color: #1e3a8a;
  --nav-dropdown-background-color: #2563eb;
  --nav-dropdown-color: #fff;
  --nav-dropdown-hover-color: #60a5fa;
}

.light-background {
  --background-color: #60a5fa;
  --surface-color: #93c5fd;
}

.dark-background {
  --background-color: #1e3a8a;
  --default-color: #fff;
  --heading-color: #e0e7ff;
  --surface-color: #2563eb;
  --contrast-color: #fff;
}

:root {
  scroll-behavior: smooth;
}

body {
  color: var(--default-color);
  background-color: var(--background-color);
  font-family: var(--default-font);
}

a {
  color: var(--accent-color);
  text-decoration: none;
  transition: all .3s;
}

a:hover {
  color: color-mix(in srgb, var(--accent-color), transparent 25%);
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}

.header {
  --background-color: #fff0;
  --heading-color: #fff;
  color: var(--default-color);
  background-color: var(--background-color);
  z-index: 997;
  padding: 20px 0;
  transition: all .5s;
}

.header .logo {
  line-height: 1;
}

.header .logo img {
  max-height: 32px;
  margin-right: 8px;
}

.header .logo h1 {
  color: var(--heading-color);
  margin: 0;
  font-size: 30px;
  font-weight: 700;
}

.scrolled .header {
  --background-color: #2a2c39e6;
  box-shadow: 0 0 18px #0000001a;
}

@media (width >= 1200px) {
  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }

  .navmenu li {
    margin-left: 5px;
    position: relative;
  }

  .navmenu a, .navmenu a:focus {
    color: var(--nav-color);
    font-size: 14px;
    font-family: var(--nav-font);
    white-space: nowrap;
    border-radius: 50px;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    font-weight: 400;
    transition: all .3s;
    display: flex;
  }

  .navmenu a i, .navmenu a:focus i {
    margin-left: 5px;
    font-size: 12px;
    line-height: 0;
    transition: all .3s;
  }

  .navmenu li:hover > a, .navmenu .active, .navmenu .active:focus {
    background-color: var(--nav-hover-color);
  }

  .navmenu .dropdown ul {
    background: var(--nav-dropdown-background-color);
    visibility: hidden;
    opacity: 0;
    z-index: 99;
    border-radius: 15px;
    margin: 0;
    padding: 10px 0;
    transition: all .3s;
    display: block;
    position: absolute;
    top: 130%;
    left: 0;
    box-shadow: 0 0 30px #0000001a;
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
    margin-left: 0;
  }

  .navmenu .dropdown ul a {
    text-transform: none;
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-size: 15px;
  }

  .navmenu .dropdown ul a i {
    font-size: 12px;
  }

  .navmenu .dropdown ul a:hover, .navmenu .dropdown ul .active:hover, .navmenu .dropdown ul li:hover > a {
    color: var(--nav-hover-color);
    background-color: #0000;
  }

  .navmenu .dropdown:hover > ul {
    opacity: 1;
    visibility: visible;
    top: 105%;
  }

  .navmenu .dropdown .dropdown ul {
    visibility: hidden;
    top: 0;
    left: -90%;
  }

  .navmenu .dropdown .dropdown:hover > ul {
    opacity: 1;
    visibility: visible;
    top: 0;
    left: -100%;
  }
}

@media (width <= 1199px) {
  .mobile-nav-toggle {
    color: var(--nav-color);
    cursor: pointer;
    margin-right: 10px;
    font-size: 28px;
    line-height: 0;
    transition: color .3s;
  }

  .navmenu {
    z-index: 9997;
    padding: 0;
  }

  .navmenu ul {
    background-color: var(--nav-mobile-background-color);
    z-index: 9998;
    border-radius: 6px;
    margin: 0;
    padding: 10px 0;
    list-style: none;
    transition: all .3s;
    display: none;
    position: absolute;
    inset: 60px 20px 20px;
    overflow-y: auto;
    box-shadow: 0 0 30px #0000001a;
  }

  .navmenu a, .navmenu a:focus {
    color: var(--nav-dropdown-color);
    font-family: var(--nav-font);
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 500;
    transition: all .3s;
    display: flex;
  }

  .navmenu a i, .navmenu a:focus i {
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-left: 5px;
    font-size: 12px;
    line-height: 0;
    transition: all .3s;
    display: flex;
  }

  .navmenu a i:hover, .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover, .navmenu .active, .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active i, .navmenu .active:focus i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul {
    z-index: 99;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    margin: 10px 20px;
    padding: 10px 0;
    transition: all .5s ease-in-out;
    display: none;
    position: static;
  }

  .navmenu .dropdown ul ul {
    background-color: #2125291a;
  }

  .navmenu .dropdown > .dropdown-active {
    background-color: #21252908;
    display: block;
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: #fff;
    z-index: 9999;
    margin-right: 0;
    font-size: 32px;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .mobile-nav-active .navmenu {
    background: #212529cc;
    transition: all .3s;
    position: fixed;
    inset: 0;
    overflow: hidden;
  }

  .mobile-nav-active .navmenu > ul {
    display: block;
  }
}

.footer {
  color: var(--default-color);
  background-color: var(--background-color);
  text-align: center;
  padding: 30px 0;
  font-size: 14px;
  position: relative;
}

.footer h3 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 700;
  position: relative;
}

.footer p {
  margin: 0 0 30px;
  padding: 0;
  font-size: 15px;
  font-style: italic;
}

.footer .social-links {
  margin: 0 0 30px;
}

.footer .social-links a {
  background: var(--accent-color);
  color: var(--contrast-color);
  text-align: center;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin: 0 4px;
  font-size: 16px;
  line-height: 1;
  transition: all .3s;
  display: flex;
}

.footer .social-links a:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
  text-decoration: none;
}

.footer .copyright {
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  padding-top: 25px;
}

.footer .credits {
  padding-top: 5px;
  font-size: 13px;
}

#preloader {
  z-index: 9999;
  background-color: var(--background-color);
  width: 100%;
  height: 100vh;
  transition: all .6s ease-out;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

#preloader:before, #preloader:after {
  content: "";
  border: 4px solid var(--accent-color);
  border-radius: 50%;
  animation: 2s cubic-bezier(0, .2, .8, 1) infinite animate-preloader;
  position: absolute;
}

#preloader:after {
  animation-delay: -.5s;
}

@keyframes animate-preloader {
  0% {
    opacity: 1;
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
  }

  100% {
    opacity: 0;
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
  }
}

.scroll-top {
  visibility: hidden;
  opacity: 0;
  z-index: 99999;
  background-color: var(--accent-color);
  border-radius: 50px;
  width: 44px;
  height: 44px;
  transition: all .4s;
  position: fixed;
  bottom: -15px;
  right: 15px;
}

.scroll-top i {
  color: var(--contrast-color);
  font-size: 24px;
  line-height: 0;
}

.scroll-top:hover {
  background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
  color: var(--contrast-color);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
  bottom: 15px;
}

@media screen and (width <= 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

section, .section {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 60px 0;
  scroll-margin-top: 77px;
  overflow: clip;
}

.section-title {
  padding-bottom: 60px;
  position: relative;
}

.section-title h2 {
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1px;
  position: relative;
}

.section-title h2:after {
  content: "";
  background: var(--accent-color);
  width: 120px;
  height: 1px;
  margin: 4px 10px;
  display: inline-block;
}

.section-title p {
  color: var(--heading-color);
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 700;
  font-family: var(--heading-font);
  margin: 0;
}

.hero {
  background: linear-gradient(0deg, var(--background-color) 0%, color-mix(in srgb, var(--background-color) 90%, white 10%) 100%);
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 100%;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hero .carousel-container {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  padding-top: 60px;
  display: flex;
  position: relative;
}

.hero h2 {
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

.hero h2 span {
  text-decoration: underline;
}

.hero p {
  max-width: 80%;
  margin: 0 auto 30px;
  animation-delay: .4s;
}

.hero .carousel-control-prev, .hero .carousel-control-next {
  width: 10%;
}

.hero .carousel-control-next-icon, .hero .carousel-control-prev-icon {
  background: none;
  width: auto;
  height: auto;
  font-size: 48px;
  line-height: 1;
}

.hero .btn-get-started {
  letter-spacing: 1px;
  color: var(--default-color);
  border: 2px solid var(--accent-color);
  border-radius: 50px;
  margin: 10px;
  padding: 12px 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  transition: all .5s;
  animation-delay: .8s;
  display: inline-block;
}

.hero .btn-get-started:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
  text-decoration: none;
}

@media (width >= 1024px) {
  .hero p {
    max-width: 60%;
  }

  .hero .carousel-control-prev, .hero .carousel-control-next {
    width: 5%;
  }
}

@media (width <= 768px) {
  .hero .carousel-container {
    min-height: 90vh;
  }

  .hero h2 {
    font-size: 28px;
  }
}

.hero .hero-waves {
  width: 100%;
  height: 60px;
  display: block;
  position: relative;
}

.hero .wave1 use {
  fill: var(--default-color);
  opacity: .6;
  animation: 10s linear -2s infinite move-forever1;
}

.hero .wave2 use {
  fill: var(--default-color);
  opacity: .4;
  animation: 8s linear -2s infinite move-forever2;
}

.hero .wave3 use {
  fill: var(--default-color);
  animation: 6s linear -2s infinite move-forever3;
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px);
  }

  100% {
    transform: translate(-90px);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px);
  }

  100% {
    transform: translate(85px);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(-90px);
  }

  100% {
    transform: translate(85px);
  }
}

.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  background-color: var(--surface-color);
  color: var(--heading-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
  cursor: pointer;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 15px 20px;
  transition: all .3s;
  display: flex;
}

.features .nav-link i {
  padding-right: 15px;
  font-size: 48px;
}

.features .nav-link h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.features .nav-link:hover {
  color: var(--accent-color);
  border-color: var(--accent-color);
}

.features .nav-link.active {
  background: var(--accent-color);
  color: var(--contrast-color);
  border-color: var(--accent-color);
}

.features .nav-link.active h4 {
  color: var(--contrast-color);
}

@media (width <= 768px) {
  .features .nav-link i {
    padding: 0;
    font-size: 36px;
    line-height: 1;
  }
}

@media (width <= 575px) {
  .features .nav-link {
    padding: 15px;
  }

  .features .nav-link i {
    font-size: 24px;
  }
}

.features .tab-pane h3 {
  color: var(--heading-color);
  font-size: 26px;
  font-weight: 700;
}

.features .tab-pane ul {
  padding: 0;
  list-style: none;
}

.features .tab-pane ul li {
  padding-bottom: 10px;
}

.features .tab-pane ul i {
  color: var(--accent-color);
  padding-right: 4px;
  font-size: 20px;
}

.features .tab-pane p:last-child {
  margin-bottom: 0;
}

.services .service-item {
  border-radius: 18px;
  height: 100%;
  padding: 60px 30px 60px 70px;
  transition: all .3s ease-in-out;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 30px #0000001a;
}

.services .service-item .icon {
  position: absolute;
  top: calc(50% - 30px);
  left: -20px;
}

.services .service-item .icon i {
  font-size: 64px;
  line-height: 1;
  transition: all .5s;
}

.services .service-item h3 {
  margin: 10px 0 15px;
  font-size: 22px;
  font-weight: 700;
  transition: all .3s ease-in-out;
}

.services .service-item p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 24px;
}

@media (width >= 1365px) {
  .services .service-item:hover {
    transform: translateY(-10px);
  }

  .services .service-item:hover h3 {
    color: var(--accent-color);
  }
}

.contact .info-item + .info-item {
  margin-top: 40px;
}

.contact .info-item i {
  color: var(--accent-color);
  background: color-mix(in srgb, var(--accent-color), transparent 92%);
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin-right: 15px;
  font-size: 20px;
  transition: all .3s ease-in-out;
  display: flex;
}

.contact .info-item h3 {
  margin-bottom: 5px;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
}

.contact .info-item p {
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
}

.contact .info-item:hover i {
  background: var(--accent-color);
  color: var(--contrast-color);
}

.widget-title {
  color: var(--heading-color);
  border-left: 4px solid var(--accent-color);
  margin: 0 0 20px;
  padding: 0 0 0 10px;
  font-size: 20px;
  font-weight: 600;
}
/*# sourceMappingURL=index.7365e0ec.css.map */
