/* Fonts */
:root {
  --default-font: "Lato", system-ui, -apple-system, "Segoe UI", Lato,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Raleway", sans-serif;
  --nav-font: "Poppins", sans-serif;
}

/* Fonts */
:root {
  --default-font: "Lato", system-ui, -apple-system, "Segoe UI", Lato,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Raleway", sans-serif;
  --nav-font: "Poppins", sans-serif;
}

/* Global Colors - Updated for Blue Background and High Contrast */
:root {
  --background-color: #ffffff;
  --default-color: #444444;
  --heading-color: #2a2c39;
  --accent-color: #60a5fa;
  --surface-color: #2563eb;
  --contrast-color: #ffffff;
}

/* Nav Menu Colors - Adjusted for Blue Theme */
:root {
  --nav-color: #e0e7ff; /* Light blue for navigation menu links */
  --nav-hover-color: #60a5fa; /* Sky blue hover effect for nav menu links */
  --nav-mobile-background-color: #1e3a8a; /* Same as the background for consistency */
  --nav-dropdown-background-color: #2563eb; /* Slightly lighter blue for dropdown items */
  --nav-dropdown-color: #ffffff; /* White text for dropdown items */
  --nav-dropdown-hover-color: #60a5fa; /* Sky blue hover for dropdown links */
}

/* Color Presets - Light and Dark Background Variants */

.light-background {
  --background-color: #60a5fa; /* Sky blue for light sections */
  --surface-color: #93c5fd; /* Even lighter blue for surface elements */
}

.dark-background {
  --background-color: #1e3a8a; /* Deep blue for dark sections */
  --default-color: #ffffff; /* White text for readability */
  --heading-color: #e0e7ff; /* Light blue for headings */
  --surface-color: #2563eb; /* Slightly lighter blue for contrast */
  --contrast-color: #ffffff; /* Maintain white for text contrasts */
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General Styling & Shared Classes
--------------------------------------------------------------*/
body {
  color: var(--default-color);
  background-color: var(--background-color);
  font-family: var(--default-font);
}

a {
  color: var(--accent-color);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: color-mix(in srgb, var(--accent-color), transparent 25%);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}

/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.header {
  --background-color: rgba(255, 255, 255, 0);
  --heading-color: #ffffff;
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 20px 0;
  transition: all 0.5s;
  z-index: 997;
}

.header .logo {
  line-height: 1;
}

.header .logo img {
  max-height: 32px;
  margin-right: 8px;
}

.header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 700;
  color: var(--heading-color);
}

.scrolled .header {
  box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
}

/* Global Header on Scroll
------------------------------*/
.scrolled .header {
  --background-color: rgba(42, 44, 57, 0.9);
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Navmenu - Desktop */
@media (min-width: 1200px) {
  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navmenu li {
    position: relative;
    margin-left: 5px;
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-color);
    padding: 8px 20px;
    font-size: 14px;
    font-family: var(--nav-font);
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
    border-radius: 50px;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }

  .navmenu li:hover > a,
  .navmenu .active,
  .navmenu .active:focus {
    background-color: var(--nav-hover-color);
  }

  .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: var(--nav-dropdown-background-color);
    display: block;
    position: absolute;
    visibility: hidden;
    left: 0;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 15px;
    z-index: 99;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
    margin-left: 0;
  }

  .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--nav-dropdown-color);
  }

  .navmenu .dropdown ul a i {
    font-size: 12px;
  }

  .navmenu .dropdown ul a:hover,
  .navmenu .dropdown ul .active:hover,
  .navmenu .dropdown ul li:hover > a {
    background-color: transparent;
    color: var(--nav-hover-color);
  }

  .navmenu .dropdown:hover > ul {
    opacity: 1;
    top: 105%;
    visibility: visible;
  }

  .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .navmenu .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }
}

/* Navmenu - Mobile */
@media (max-width: 1199px) {
  .mobile-nav-toggle {
    color: var(--nav-color);
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    display: none;
    list-style: none;
    position: absolute;
    inset: 60px 20px 20px 20px;
    padding: 10px 0;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-mobile-background-color);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active i,
  .navmenu .active:focus i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    transition: all 0.5s ease-in-out;
  }

  .navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .dropdown > .dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    font-size: 32px;
    top: 15px;
    right: 15px;
    margin-right: 0;
    z-index: 9999;
  }

  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .mobile-nav-active .navmenu > ul {
    display: block;
  }
}

/*--------------------------------------------------------------
# Global Footer
--------------------------------------------------------------*/
.footer {
  color: var(--default-color);
  background-color: var(--background-color);
  font-size: 14px;
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.footer h3 {
  font-size: 36px;
  font-weight: 700;
  position: relative;
  padding: 0;
  margin: 0 0 15px 0;
}

.footer p {
  font-size: 15;
  font-style: italic;
  padding: 0;
  margin: 0 0 30px 0;
}

.footer .social-links {
  margin: 0 0 30px 0;
}

.footer .social-links a {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--accent-color);
  color: var(--contrast-color);
  line-height: 1;
  margin: 0 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
  text-decoration: none;
}

.footer .copyright {
  padding-top: 25px;
  border-top: 1px solid
    color-mix(in srgb, var(--default-color), transparent 90%);
}

.footer .credits {
  font-size: 13px;
  padding-top: 5px;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background-color: var(--background-color);
  transition: all 0.6s ease-out;
  width: 100%;
  height: 100vh;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  border: 4px solid var(--accent-color);
  border-radius: 50%;
  animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader:after {
  animation-delay: -0.5s;
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Scroll Top Button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: -15px;
  z-index: 99999;
  background-color: var(--accent-color);
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: var(--contrast-color);
  line-height: 0;
}

.scroll-top:hover {
  background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
  color: var(--contrast-color);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Global Sections
--------------------------------------------------------------*/
section,
.section {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 60px 0;
  scroll-margin-top: 77px;
  overflow: clip;
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.section-title {
  padding-bottom: 60px;
  position: relative;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  position: relative;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: var(--accent-color);
  margin: 4px 10px;
}

.section-title p {
  color: var(--heading-color);
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: var(--heading-font);
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: linear-gradient(
    0deg,
    var(--background-color) 0%,
    color-mix(in srgb, var(--background-color) 90%, white 10%) 100%
  );
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  min-height: 75vh;
  padding-top: 60px;
}

.hero h2 {
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

.hero h2 span {
  text-decoration: underline;
}

.hero p {
  max-width: 80%;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
}

.hero .carousel-control-prev,
.hero .carousel-control-next {
  width: 10%;
}

.hero .carousel-control-next-icon,
.hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}

.hero .btn-get-started {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  animation-delay: 0.8s;
  color: var(--default-color);
  border: 2px solid var(--accent-color);
}

.hero .btn-get-started:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
  text-decoration: none;
}

@media (min-width: 1024px) {
  .hero p {
    max-width: 60%;
  }

  .hero .carousel-control-prev,
  .hero .carousel-control-next {
    width: 5%;
  }
}

@media (max-width: 768px) {
  .hero .carousel-container {
    min-height: 90vh;
  }

  .hero h2 {
    font-size: 28px;
  }
}

.hero .hero-waves {
  display: block;
  width: 100%;
  height: 60px;
  position: relative;
}

.hero .wave1 use {
  animation: move-forever1 10s linear infinite;
  animation-delay: -2s;
  fill: var(--default-color);
  opacity: 0.6;
}

.hero .wave2 use {
  animation: move-forever2 8s linear infinite;
  animation-delay: -2s;
  fill: var(--default-color);
  opacity: 0.4;
}

.hero .wave3 use {
  animation: move-forever3 6s linear infinite;
  animation-delay: -2s;
  fill: var(--default-color);
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}
/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  background-color: var(--surface-color);
  color: var(--heading-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
  padding: 15px 20px;
  transition: 0.3s;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
}

.features .nav-link i {
  padding-right: 15px;
  font-size: 48px;
}

.features .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.features .nav-link:hover {
  color: var(--accent-color);
  border-color: var(--accent-color);
}

.features .nav-link.active {
  background: var(--accent-color);
  color: var(--contrast-color);
  border-color: var(--accent-color);
}

.features .nav-link.active h4 {
  color: var(--contrast-color);
}

@media (max-width: 768px) {
  .features .nav-link i {
    padding: 0;
    line-height: 1;
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .features .nav-link {
    padding: 15px;
  }

  .features .nav-link i {
    font-size: 24px;
  }
}

.features .tab-pane h3 {
  color: var(--heading-color);
  font-weight: 700;
  font-size: 26px;
}

.features .tab-pane ul {
  list-style: none;
  padding: 0;
}

.features .tab-pane ul li {
  padding-bottom: 10px;
}

.features .tab-pane ul i {
  font-size: 20px;
  padding-right: 4px;
  color: var(--accent-color);
}

.features .tab-pane p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .service-item {
  /* background-color: var(--surface-color); */
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  padding: 60px 30px 60px 70px;
  transition: all ease-in-out 0.3s;
  border-radius: 18px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.services .service-item .icon {
  position: absolute;
  left: -20px;
  top: calc(50% - 30px);
}

.services .service-item .icon i {
  font-size: 64px;
  line-height: 1;
  transition: 0.5s;
}

.services .service-item h3 {
  font-weight: 700;
  margin: 10px 0 15px 0;
  font-size: 22px;
  transition: ease-in-out 0.3s;
}

.services .service-item p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

@media (min-width: 1365px) {
  .services .service-item:hover {
    transform: translateY(-10px);
  }

  .services .service-item:hover h3 {
    color: var(--accent-color);
  }
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item + .info-item {
  margin-top: 40px;
}

.contact .info-item i {
  color: var(--accent-color);
  background: color-mix(in srgb, var(--accent-color), transparent 92%);
  font-size: 20px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contact .info-item h3 {
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact .info-item:hover i {
  background: var(--accent-color);
  color: var(--contrast-color);
}

/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widget-title {
  color: var(--heading-color);
  font-size: 20px;
  font-weight: 600;
  padding: 0 0 0 10px;
  margin: 0 0 20px 0;
  border-left: 4px solid var(--accent-color);
}
